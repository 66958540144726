<template>
  <v-card class="px-0 mt-0" height="500">
    <div
      class="d-flex flex-row justify-end px-14 ma-0 mt-n16 mb-10"
      v-if="$attrs.lesson.courseInfo.GRADING_SCHEME_ID == 14"
    >
      <v-btn color="blue" @click="_confirmAll()" class="white--text"
        >Бүгдийг тооцох</v-btn
      >
    </div>

    <template>
      <v-simple-table
        class="px-10 pt-2 pb-2"
        style="height: 100%; overflow-y: auto"
      >
        <tbody>
          <tr style="background-color: #ececec" class="mt-2 pt-2">
            <th class="font-weight-bold black--text text-center">No.</th>
            <th class="font-weight-bold black--text pl-2">Нэр</th>
            <th class="font-weight-bold black--text  text-center">Төрсөн огноо</th>
            <th
              v-if="
                $attrs.lesson.esisLessonType &&
                $attrs.lesson.esisLessonType.esisLessonTypeId == 3
              "
            >
              Бүлэг
            </th>
            <th class="font-weight-bold black--text text-center">Дүн (%)</th>
            <th class="font-weight-bold black--text text-center">Үнэлгээ</th>
            <th class="font-weight-bold black--text text-center">Тайлбар</th>
          </tr>
          <tr v-for="(item, ii) in filteredStudents" :key="ii + item.id">
            <td class="text-center" @click="_print(item)" style="width: 1%">
              {{ ii + 1 }}
            </td>
            <td class="px-2" style="width: 40%">
              {{ item["FIRST_NAME"]
              }}<span class="grey--text"
                >,
                {{ item["LAST_NAME"] }}
              </span>
            </td>
            <td class="text-center">{{item.DATE_OF_BIRTH.replace("T00:00:00.000Z","")}}</td>
            <td
              v-if="
                $attrs.lesson.esisLessonType &&
                $attrs.lesson.esisLessonType.esisLessonTypeId == 3
              "
            >
              <span class="blue--text"> {{ item["STUDENT_GROUP_NAME"] }}</span>
            </td>
            <td style="width: 15%">
              <input
                class="text-center"
                :id="item.id + 'score'"
                type="number"
                v-model.number="item.score"
                style="width: 100%"
                :max="100"
                :min="0"
                @click="
                  item.score != null &&
                  item.score != undefined &&
                  typeof item.score == 'number'
                    ? (previousValue = item.score)
                    : (previousValue = null)
                "
                @keyup="checkValue(item)"
                @change="calScoreLevel(item)"
              />
            </td>
            <td class="text-center font-weight-bold">
              {{ item.scoreLevelString }}
            </td>
            <td style="width: 25%">
              <input
                class="text-center"
                :id="item.id + 'description'"
                v-model.trim="item.description"
                style="width: 100%"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </template>
  </v-card>
</template>

<script>
// const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import myfunction from "@/components/global/myfunction.js";
export default {
  components: {},
  data: () => ({
    studentsStatuses: null,
    alertText: null,
    showAlert: false,
    selectedEeljTsag: null,
    selectedLessonDayReport: null,
    attends: null,
    selectedStudent: null,
    selectedXXDay: null,
    selectedReasons: null,
    forParents: false,
    forStudent: false,
    forClassTeacher: false,
    forManager: false,
    xlessonStudentNotes: null,
    noteAdded: null,
    dialogAddNote: false,

    query: null,
    bodyNames: null,
    endOfDay: 30,
    collectionName: "attendances",
    docName: "attendance",

    currentDays: null,
    teachedTopics: null,
    curriculumDialog: null,
    selectedSubtopic: null,
    // selectedHomeworks: null,
    newDaalgavarName: null,
    previousSelectedDaalgavar: null,
    headerNames: [
      {
        text: "No.",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
      },

      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "firstName",
      },
    ],
    previousValue: null,
  }),
  mixins: [myfunction],
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    _getFilteredGroups() {
      console.log(this.$attrs.lesson);
      console.log(this.$attrs.currentSelectedExam);
      // $attrs.lesson.lessonGroups
      var list = [];
      for (const lg of this.$attrs.lesson.lessonGroups) {
        if (
          this.$attrs.currentSelectedExam.lessonGroup &&
          lg.id == this.$attrs.currentSelectedExam.lessonGroup.id
        ) {
          list.push(lg);
        } else if (!this.$attrs.currentSelectedExam.lessonGroup) {
          list.push(lg);
        }
      }
      console.log(list);
      return list;
    },
    filteredStudents() {
      var list = [];
      if (this.$attrs.students != null) {
        var counter = 0;
        for (var stud of this.$attrs.students) {
          counter++;
          stud.index = counter;
          list.push(stud);
        }
      }
      return list;
    },
  },
  created() {
    // console.log(this.$attrs.students);
  },
  mounted() {
    console.log("ddd");
  },
  watch: {},

  methods: {
    _confirmAll() {
      for (const stud of this.$attrs.students) {
        stud.score = 100;
        this.calScoreLevel(stud);
        console.log(stud.firstName, stud.score);
      }
      this.$forceUpdate();
    },
    checkValue(item) {
      if (this.previousValue) {
        if (item.score > 100) {
          item.score = this.previousValue;
        } else {
          if (String(item.score) != String(this.previousValue)[0] && item.score)
            this.previousValue = item.score;
        }
      } else {
        if (item.score > 100) {
          item.score = 100;
          this.previousValue = 100;
        }
      }
      this.$forceUpdate();
    },
    async calScoreLevel(item) {
      console.log(item.score);
      let gradePoints;
      let gradeStr;

      if (item.score >= 90) {
        gradePoints = 8;
        gradeStr = "VIII";
      } else if (item.score >= 80) {
        gradePoints = 7;
        gradeStr = "VII";
      } else if (item.score >= 70) {
        gradePoints = 6;
        gradeStr = "VI";
      } else if (item.score >= 60) {
        gradePoints = 5;
        gradeStr = "V";
      } else if (item.score >= 50) {
        gradePoints = 4;
        gradeStr = "IV";
      } else if (item.score >= 40) {
        gradePoints = 3;
        gradeStr = "III";
      } else if (item.score >= 30) {
        gradePoints = 2;
        gradeStr = "II";
      } else if (item.score >= 0 && typeof item.score == "number") {
        gradePoints = 1;
        gradeStr = "I";
      } else if (
        item.score == null ||
        item.score == undefined ||
        typeof item.score != "number"
      ) {
        gradePoints = null;
        gradeStr = null;
      }

      item.scoreLevelNumber = gradePoints;
      item.scoreLevelString = gradeStr;
      console.log(item.scoreLevelNumber, item.scoreLevelString);
      if (
        item.score > 0 &&
        this.$attrs.lesson.courseInfo.GRADING_SCHEME_ID == 14
      ) {
        item.scoreLevelString = "Тооцов";
      } else if (
        item.score == 0 &&
        this.$attrs.lesson.courseInfo.GRADING_SCHEME_ID == 14
      ) {
        item.scoreLevelString = "Тооцоогүй";
      }
      this.renderComponent = false;
      await this.$nextTick();
      this.renderComponent = true;
    },
    _print(item) {
      console.log("xxx", item.ref.path);
    },
    _getCurrentDateAsString(studentId) {
      if (this.forClass) {
        return (
          this.userData.school.currentYear +
          "-" +
          this.selectedMonth +
          "-" +
          this.selectedXXDay +
          "-" +
          studentId
        );
      } else {
        return (
          this.userData.school.currentYear +
          "-" +
          this.selectedMonth +
          "-" +
          this.selectedXXDay +
          "-" +
          this.selectedEeljTsag +
          "-" +
          studentId
        );
      }
    },
  },
};
</script>
<style>
.scoreTables td {
  border: 1px solid #dddddd;
  text-align: left;
  height: 35px !important;
  padding: 0 !important;
}

.scoreTables th {
  border: 1px solid #dddddd;
  text-align: left;
  height: 15px !important;
  padding: 0 !important;
}
</style>
