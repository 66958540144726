var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"px-0 pb-10"},[_c('v-snackbar',{attrs:{"timeout":"2000","top":"","color":"bg-gradient-success"},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({staticClass:"font-weight-bold",attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Xааx ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.actionstatus)+" ")]),_c('v-card-text',{staticClass:"px-4 py-0 scoreTabless mt-4 mb-10"},[(_vm.loading == false)?[[(_vm.xStudents)?_c('v-data-table',{attrs:{"headers":_vm.$attrs.examInfos,"items":_vm.xStudents,"items-per-page":-1,"search":_vm.search,"hide-default-header":"","hide-details":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"header",fn:function({ props: { headers } }){return [_c('tr',[_c('th',{staticStyle:{"width":"1%"}},[_vm._v("No.")]),_c('th',{staticStyle:{"width":"15%"}},[_vm._v("Нэр")]),(
                  _vm.$attrs.lesson.esisLessonType &&
                  _vm.$attrs.lesson.esisLessonType.esisLessonTypeId == 3
                )?_c('th',{staticStyle:{"width":"5%"}},[_vm._v(" Бүлэг ")]):_vm._e(),_vm._l((headers),function(header){return _c('th',{key:header.id,staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._getShortExamName(header))+" "),_c('p',{staticClass:"blue--text"},[_vm._v(_vm._s(header.examDate))])])})],2),_c('tr',[_c('th'),_c('th'),_vm._l((headers),function(header){return _c('th',{key:header.id,staticClass:"text-center"},[(header.scoreSummerizationInfo)?_vm._l(([1, 2, 3, 4, 5, 6, 7, 8]),function(iIndex){return _c('span',{key:'lvel' + iIndex},[_vm._v(" "+_vm._s(_vm.romanNumbers[iIndex - 1])+": "+_vm._s(header.scoreSummerizationInfo["numberOfLevel" + iIndex]))])}):_vm._e()],2)})],2)]}},{key:"item",fn:function(props){return [_c('tr',[_c('td',{staticClass:"pl-1",staticStyle:{"width":"1%"},on:{"click":function($event){return _vm._print(props.item)}}},[_vm._v(" "+_vm._s(props.item.numbering)+" ")]),_c('td',{staticClass:"pl-1"},[_vm._v(" "+_vm._s(props.item["LAST_NAME"][0] + ". " + props.item["FIRST_NAME"])+" "),_c('span',{staticClass:"grey--text"},[_vm._v(" /"+_vm._s(props.item.DATE_OF_BIRTH.replace("T00:00:00.000Z", ""))+"/")])]),(
                  _vm.$attrs.lesson.esisLessonType &&
                  _vm.$attrs.lesson.esisLessonType.esisLessonTypeId == 3
                )?_c('td',{staticClass:"blue--text",staticStyle:{"width":"5%"}},[_vm._v(" "+_vm._s(props.item.STUDENT_GROUP_NAME)+" ")]):_vm._e(),_vm._l((_vm.$attrs.examInfos),function(exam){return _c('td',{key:'exam-' + exam.id,staticClass:"text-center pl-0",staticStyle:{"width":"3%"},on:{"click":function($event){return _vm._printScore(props.item, exam)}}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-0",staticStyle:{"height":"25px","padding-top":"2px !important","border-right":"2px solid #dddddd"}},[_vm._v(_vm._s(_vm._getExamScore(props.item, exam)))]),_c('v-col',{staticClass:"pa-0",staticStyle:{"height":"25px","padding-top":"2px !important"}},[_vm._v(_vm._s(_vm._getExamScore2(props.item, exam)))])],1)],1)})],2)]}}],null,false,604535762)}):_vm._e()]]:_c('div',[_c('v-card',{attrs:{"height":"400"}},[_c('v-progress-linear',{attrs:{"color":"red","height":"6","indeterminate":""}})],1)],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }