var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"px-0 mt-0",attrs:{"height":"500"}},[(_vm.$attrs.lesson.courseInfo.GRADING_SCHEME_ID == 14)?_c('div',{staticClass:"d-flex flex-row justify-end px-14 ma-0 mt-n16 mb-10"},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"blue"},on:{"click":function($event){return _vm._confirmAll()}}},[_vm._v("Бүгдийг тооцох")])],1):_vm._e(),[_c('v-simple-table',{staticClass:"px-10 pt-2 pb-2",staticStyle:{"height":"100%","overflow-y":"auto"}},[_c('tbody',[_c('tr',{staticClass:"mt-2 pt-2",staticStyle:{"background-color":"#ececec"}},[_c('th',{staticClass:"font-weight-bold black--text text-center"},[_vm._v("No.")]),_c('th',{staticClass:"font-weight-bold black--text pl-2"},[_vm._v("Нэр")]),_c('th',{staticClass:"font-weight-bold black--text text-center"},[_vm._v("Төрсөн огноо")]),(
              _vm.$attrs.lesson.esisLessonType &&
              _vm.$attrs.lesson.esisLessonType.esisLessonTypeId == 3
            )?_c('th',[_vm._v(" Бүлэг ")]):_vm._e(),_c('th',{staticClass:"font-weight-bold black--text text-center"},[_vm._v("Дүн (%)")]),_c('th',{staticClass:"font-weight-bold black--text text-center"},[_vm._v("Үнэлгээ")]),_c('th',{staticClass:"font-weight-bold black--text text-center"},[_vm._v("Тайлбар")])]),_vm._l((_vm.filteredStudents),function(item,ii){return _c('tr',{key:ii + item.id},[_c('td',{staticClass:"text-center",staticStyle:{"width":"1%"},on:{"click":function($event){return _vm._print(item)}}},[_vm._v(" "+_vm._s(ii + 1)+" ")]),_c('td',{staticClass:"px-2",staticStyle:{"width":"40%"}},[_vm._v(" "+_vm._s(item["FIRST_NAME"])),_c('span',{staticClass:"grey--text"},[_vm._v(", "+_vm._s(item["LAST_NAME"])+" ")])]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.DATE_OF_BIRTH.replace("T00:00:00.000Z","")))]),(
              _vm.$attrs.lesson.esisLessonType &&
              _vm.$attrs.lesson.esisLessonType.esisLessonTypeId == 3
            )?_c('td',[_c('span',{staticClass:"blue--text"},[_vm._v(" "+_vm._s(item["STUDENT_GROUP_NAME"]))])]):_vm._e(),_c('td',{staticStyle:{"width":"15%"}},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(item.score),expression:"item.score",modifiers:{"number":true}}],staticClass:"text-center",staticStyle:{"width":"100%"},attrs:{"id":item.id + 'score',"type":"number","max":100,"min":0},domProps:{"value":(item.score)},on:{"click":function($event){item.score != null &&
                item.score != undefined &&
                typeof item.score == 'number'
                  ? (_vm.previousValue = item.score)
                  : (_vm.previousValue = null)},"keyup":function($event){return _vm.checkValue(item)},"change":function($event){return _vm.calScoreLevel(item)},"input":function($event){if($event.target.composing)return;_vm.$set(item, "score", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('td',{staticClass:"text-center font-weight-bold"},[_vm._v(" "+_vm._s(item.scoreLevelString)+" ")]),_c('td',{staticStyle:{"width":"25%"}},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(item.description),expression:"item.description",modifiers:{"trim":true}}],staticClass:"text-center",staticStyle:{"width":"100%"},attrs:{"id":item.id + 'description'},domProps:{"value":(item.description)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(item, "description", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})])])})],2)])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }